import PlanCardNew from "@components/PlanCardNew"
import { StripePrice } from "src/types"

interface Props {
  currency: string;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  eligibleForIntroPrice: boolean;
  weeklyIntroPrice: StripePrice | undefined;
  weeklyPrice: StripePrice | undefined;
}

export default function Plans({
  eligibleForIntroPrice,

  currency,
  isSubmitting,
  setIsSubmitting,
  weeklyIntroPrice,
  weeklyPrice,
}: Props) {
  return (
    <div className={"grid grid-cols-1 items-end gap-8 md:grid-cols-1"}>
      {/* <PlanCardNew
          bestOffer
          currency={currency}
          isSubmitting={isSubmitting}
          periodAmount={12}
          periodUnit="month"
          plan="yearly"
        setIsSubmitting={setIsSubmitting}
        stripeIntroPriceCoupon={introPriceCoupon}
          stripePrice={yearlyPrice}
        /> */}

      <PlanCardNew
        currency={currency}
        isSubmitting={isSubmitting}
        periodAmount={1}
        periodUnit="week"
        plan="weekly"
        setIsSubmitting={setIsSubmitting}
        eligibleForIntroPrice={eligibleForIntroPrice}
        stripeIntroPrice={weeklyIntroPrice}
        stripePrice={weeklyPrice}
      />
    </div>
  )
}
