import turnHeadsFemale from "@assets/turn-heads-f.webp"
import turnHeadsMale from "@assets/turn-heads-m.webp"
import Button from "@components/Button"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"
import { useTranslation } from "react-i18next"
import { Form } from "react-router-dom"

export default function TurnHeadsPage() {
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()
  return (
    <main className="custom-container flex h-dvh flex-col justify-between space-y-14 overflow-y-auto pt-8">
      <div className="flex h-full flex-col text-center">
        <div className="justify-top flex grow flex-col items-center">
          <h1 className="heading my-3">
            {t("pages.onboarding.v1.turnHeads.heading")}
          </h1>

          <h2 className="px-4 text-center font-openSansSemiBold text-base text-gray-400">
            {t("pages.onboarding.v1.turnHeads.subtitle")}
          </h2>

          <img
            className="w-full max-w-xs px-8 py-16"
            src={isFemale ? turnHeadsFemale : turnHeadsMale}
            alt="turn heads"
          />
        </div>

        <Form className="py-8" method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>
    </main>
  )
}
