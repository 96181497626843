import appStoreAppOfTheYear2023White from "@assets/app_store_app_of_the_day_2023_white.webp"
import bestLookFemale from "@assets/bestLookFemale.webp"
import bestLookMale from "@assets/bestLookMale.webp"
import googleEditorsChoice2023White from "@assets/google_editors_choice_2023_white.webp"
import Button from "@components/Button"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"
import { useTranslation } from "react-i18next"
import { Form } from "react-router-dom"

export default function OneMoreThingPage() {
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()
  return (
    <main className="custom-container flex h-dvh flex-col justify-between space-y-14 overflow-y-auto pt-4">
      <div className="flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center">
          <h1 className="heading my-3">
            {t("pages.onboarding.v1.oneMoreThing.heading")}
          </h1>

          <h2 className="px-4 text-center font-openSansSemiBold text-base text-white">
            {t("pages.onboarding.v1.oneMoreThing.subtitle")}
          </h2>

          <img
            className="w-full max-w-xs px-8 py-8"
            src={isFemale ? bestLookFemale : bestLookMale}
            alt="best look"
          />

          <h2 className="px-4 text-center font-openSansSemiBold text-base text-white">
            {t("pages.onboarding.v1.oneMoreThing.description")}
          </h2>

          <div className="flex items-center justify-center space-x-5 pt-4">
            <img
              alt={t("pages.onboarding.company.appStoreAppOfTheYear2023")}
              className="w-36"
              src={appStoreAppOfTheYear2023White}
            />

            <img
              alt={t("pages.onboarding.company.googleEditorsChoice2023")}
              className="w-36"
              src={googleEditorsChoice2023White}
            />
          </div>
        </div>

        <Form className="py-8" method="post">
          <Button
            title={t("pages.onboarding.v2.oneMoreThing.button")}
            type="submit"
          />
        </Form>
      </div>
    </main>
  )
}
