import Dialog from "@components/Dialog"
import SubscriptionForm from "@components/SubscriptionForm"
import localizeCurrency from "@helpers/localizeCurrency"
import { useTranslation } from "react-i18next"
import { StripePrice } from "src/types"

interface Props {
  currency: string;
  eligibleForIntroPrice: boolean;
  isSubmitting: boolean;
  open: boolean;
  onClose: () => void;
  periodAmount: number;
  periodUnit: string;
  setIsSubmitting: (isSubmitting: boolean) => void;
  stripeIntroPrice: StripePrice | undefined;
  stripePrice: StripePrice | undefined;
}

export default function LimitedTimeOfferDialog({
  currency,
  eligibleForIntroPrice,
  isSubmitting,
  onClose,
  open,
  periodAmount,
  periodUnit,
  setIsSubmitting,
  stripeIntroPrice,
  stripePrice,
}: Props) {
  const { t } = useTranslation()

  if (!stripeIntroPrice || !stripePrice) return null

  const hasCurrencyOption =
    !!stripePrice.currency_options?.[currency]?.unit_amount_decimal

  const price = parseFloat(
    hasCurrencyOption
      ? stripePrice.currency_options[currency].unit_amount_decimal
      : stripePrice?.unit_amount_decimal,
  )

  const priceCurrency = hasCurrencyOption ? currency : stripePrice.currency

  if (!price) return null

  const introPrice = parseFloat(
    hasCurrencyOption
      ? stripeIntroPrice.currency_options[currency].unit_amount_decimal
      : stripeIntroPrice?.unit_amount_decimal,
  )
  const localizePrice = (price: number) =>
    localizeCurrency({ amountInCents: price, currency: priceCurrency })

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="text-center font-openSans">
        <p className="mb-2 font-openSansSemiBold text-2xl">
          {t("pages.checkout.plans.dialog.limitedTimeOffer")}
        </p>

        <p className="font-openSansSemiBold text-4xl">{periodAmount}</p>

        <p className="text-lg mb-3 uppercase font-openSansSemiBold">
          {t(`pages.checkout.plans.${periodUnit}`, { count: periodAmount })}
        </p>

        {eligibleForIntroPrice && (
          <p className="mb-2 line-through font-openSans">{localizePrice(price)}</p>
        )}

        <p className="mb-4 font-openSansSemiBold">
          {t("pages.checkout.plans.firstYearSpecialOffer", {
            price: localizePrice(eligibleForIntroPrice ? introPrice : price),
          })}
        </p>

        <SubscriptionForm
          buttonClassName="theme-button-primary-black"
          buttonTitle={t("pages.checkout.plans.dialog.startNow")}
          currency={priceCurrency}
          isSubmitting={isSubmitting}
          plan="weekly"
          priceId={
            eligibleForIntroPrice ? stripeIntroPrice.id : stripePrice.id
          }
          setIsSubmitting={setIsSubmitting}
        />

        <p className="mt-4 font-openSansSemiBold">
          {t("pages.checkout.plans.moneyBackGuarantee")}
        </p>
      </div>
    </Dialog>
  )
}
