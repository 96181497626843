import hairstyleFemale from "@assets/loadingF.webp"
import hairstyleMale from "@assets/loadingM.webp"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"
import { CheckCircleIcon } from "@heroicons/react/24/solid"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Oval } from "react-loader-spinner"
import { useNavigate } from "react-router-dom"

export default function PreparingPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isFemale = getIsFemaleFromLocalStorage()
  const [isPreferencesSaved, setIsPreferencesSaved] = useState(false)
  const [isAiModelsReady, setIsAiModelsReady] = useState(false)
  const [isFineTuningModels, setIsFineTuningModels] = useState(false)

  useEffect(() => {
    const timeoutPreferencesSaved = setTimeout(() => {
      setIsPreferencesSaved(true)
    }, 2000)

    const timeoutAiModelsReady = setTimeout(() => {
      setIsAiModelsReady(true)
    }, 4000)

    const timeoutFineTuningModels = setTimeout(() => {
      setIsFineTuningModels(true)
    }, 6000)

    const timeoutNavigate = setTimeout(() => {
      navigate("/checkout/plans")
    }, 7000)

    return () => {
      clearTimeout(timeoutPreferencesSaved)
      clearTimeout(timeoutAiModelsReady)
      clearTimeout(timeoutFineTuningModels)
      clearTimeout(timeoutNavigate)
    }
  }, [navigate])

  return (
    <main className="custom-container flex h-dvh flex-col justify-between space-y-14 overflow-y-auto pt-8">
      <div className="flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center gap-8">
          <img
            className="w-full max-w-xs pt-8"
            src={isFemale ? hairstyleFemale : hairstyleMale}
            alt="best look"
          />

          <h1 className="heading my-3">{t("pages.signUp.ready.heading")}</h1>

          {/* <h2 className="text-center text-gray-400 font-openSansSemiBold text-base px-4">
            {t("pages.onboarding.bestLook.subtitle")}
          </h2> */}

          <div className="flex items-center justify-center gap-2">
            <h2
              className={`font-openSans text-base ${isPreferencesSaved ? "text-white" : "text-gray-400"}`}
            >
              {t("pages.signUp.ready.preferencesSaved")}
            </h2>
            {isPreferencesSaved ? (
              <CheckCircleIcon className="theme-bullet-point h-6 w-6 shrink-0" />
            ) : (
              <Oval
                color="white"
                secondaryColor="gray"
                height={20}
                width={20}
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            )}
          </div>

          <div className="flex items-center justify-center gap-2">
            <h2
              className={`font-openSans text-base ${isAiModelsReady ? "text-white" : "text-gray-400"}`}
            >
              {t("pages.signUp.ready.aiModelsAreReady1")}
            </h2>
            {isAiModelsReady ? (
              <CheckCircleIcon className="theme-bullet-point h-6 w-6 shrink-0" />
            ) : (
              <Oval
                color="white"
                secondaryColor="gray"
                height={20}
                width={20}
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            )}
          </div>

          <div className="flex items-center justify-center gap-2">
            <h2
              className={`font-openSans text-base ${isFineTuningModels ? "text-white" : "text-gray-400"}`}
            >
              {t("pages.signUp.ready.fineTuningModels")}
            </h2>
            {isFineTuningModels ? (
              <CheckCircleIcon className="theme-bullet-point h-6 w-6 shrink-0" />
            ) : (
              <Oval
                color="white"
                secondaryColor="gray"
                height={20}
                width={20}
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  )
}
