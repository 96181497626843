import futureAwaitsFemale from "@assets/future_awaits_f.webp"
import futureAwaitsMale from "@assets/future_awaits_m.webp"
import Button from "@components/Button"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"
import { useTranslation } from "react-i18next"
import { Form } from "react-router-dom"

export default function FutureAwaitsPage() {
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()
  return (
    <main className="custom-container flex h-dvh flex-col justify-between space-y-14 overflow-y-auto pt-2">
      <div className="flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center gap-4">
          <h1 className="heading my-3">
            {t("pages.onboarding.v2.futureAwaits.heading")}
          </h1>

          <h2 className="rounded-lg bg-white/30 px-4 py-2 text-center font-openSansSemiBold text-base text-white">
            {t("pages.onboarding.v2.futureAwaits.description")}
          </h2>

          <img
            className="w-full max-w-xs px-8 pt-2"
            src={isFemale ? futureAwaitsFemale : futureAwaitsMale}
            alt="future awaits"
          />
        </div>

        <Form className="py-8" method="post">
          <Button
            title={t("pages.onboarding.v2.futureAwaits.button")}
            type="submit"
          />
        </Form>
      </div>
    </main>
  )
}
