import SubscriptionForm from "@components/SubscriptionForm"
import localizeCurrency from "@helpers/localizeCurrency"
import { useTranslation } from "react-i18next"
import { Plan, StripePrice } from "src/types"

interface Props {
  bestOffer?: boolean;
  currency: string;
  eligibleForIntroPrice: boolean;
  isSubmitting: boolean;
  periodAmount: number;
  periodUnit: string;
  plan: Plan;
  setIsSubmitting: (isSubmitting: boolean) => void;
  stripeIntroPrice: StripePrice | undefined;
  stripePrice: StripePrice | undefined;
}

export default function PlanCardNew({
  bestOffer,
  currency,
  eligibleForIntroPrice,
  isSubmitting,
  periodAmount,
  periodUnit,
  plan,
  setIsSubmitting,
  stripeIntroPrice,
  stripePrice,
}: Props) {
  const { t } = useTranslation()

  if (!stripePrice || !stripeIntroPrice) return null

  const hasCurrencyOption =
    !!stripeIntroPrice.currency_options?.[currency]?.unit_amount_decimal

  const price = parseFloat(
    hasCurrencyOption
      ? stripePrice.currency_options[currency].unit_amount_decimal
      : stripePrice?.unit_amount_decimal,
  )

  const introPrice = parseFloat(
    hasCurrencyOption
      ? stripeIntroPrice.currency_options[currency].unit_amount_decimal
      : stripeIntroPrice?.unit_amount_decimal,
  )

  const priceCurrency = hasCurrencyOption ? currency : stripePrice.currency

  if (!price) return null

  const pricePerPeriod = price / periodAmount
  const introPricePerPeriod = introPrice / periodAmount

  const localizePrice = (price: number | undefined) => {
    if (!price) return ""

    return localizeCurrency({
      amountInCents: price,
      currency: priceCurrency,
    })
  }

  const localizeBilledAt = () => {
    let translationKey = "pages.checkout.plans."

    switch (plan as string) {
    case "weekly":
      translationKey += "billedWeeklyAt"
      break
    case "yearly":
      translationKey += "billedAnnuallyAt"
      break
    default:
      throw new Error(`Unhandled plan: ${plan}`)
    }

    return t(translationKey, { price: localizePrice(price) })
  }

  const localizeSpecialOffer = () => {
    if (!eligibleForIntroPrice) return localizeBilledAt()

    let translationKey = "pages.checkout.plans."

    switch (plan as string) {
    case "weekly":
      translationKey += "firstWeekSpecialOffer"
      break
    case "yearly":
      translationKey += "firstYearSpecialOffer"
      break
    default:
      throw new Error(`Unhandled plan: ${plan}`)
    }

    return t(translationKey, {
      price: localizePrice(eligibleForIntroPrice ? introPrice : price),
    })
  }

  return (
    <div>
      <div className="group">
        {/* {bestOffer && (
          <div className="theme-plan-card-white -mb-8 rounded-t-3xl border p-5 pb-12 text-center">
            <p className="font-openSansSemiBold text-2xl uppercase">
              {t("pages.checkout.plans.bestOffer")}
            </p>
          </div>
        )} */}

        <div className="theme-plan-card rounded-3xl border-0 px-6 pb-12 pt-5 text-center">
          {/* <p className="font-openSansSemiBold text-4xl">{periodAmount}</p> */}

          {/* <p className="mt-3 text-lg font-openSans uppercase">
            {t(`pages.checkout.plans.${periodUnit}`, { count: periodAmount })}
          </p> */}

          {eligibleForIntroPrice && (
            <p className="mt-3 text-2xl line-through font-openSans">
              {localizePrice(pricePerPeriod)}
            </p>
          )}

          <p className="mt-2 font-openSansSemiBold text-2xl">
            {localizePrice(
              eligibleForIntroPrice ? introPricePerPeriod : pricePerPeriod,
            )}{" "}
             / {t(`pages.checkout.plans.${periodUnit}`, { count: 1 })}
          </p>

          {eligibleForIntroPrice && (
            <p className="theme-plan-card-gray mt-4 line-through font-openSans">
              {localizeBilledAt()}
            </p>
          )}

          <p className="theme-plan-card-gray font-openSansSemiBold">
            {localizeSpecialOffer()}
          </p>

          <SubscriptionForm
            buttonClassName={`${bestOffer ? "theme-button-primary" : "theme-button-primary"} mt-5 uppercase`}
            buttonTitle={t("pages.checkout.plans.subscribeNow")}
            currency={priceCurrency}
            isSubmitting={isSubmitting}
            plan={plan}
            priceId={
              eligibleForIntroPrice ? stripeIntroPrice.id : stripePrice.id
            }
            setIsSubmitting={setIsSubmitting}
          />

          <p className="mt-7 font-openSans text-[#eff926]">{t("pages.checkout.plans.cancelAnytime")}</p>

          <p className="mt-3 font-openSansSemiBold text-[#eff926]">
            {t("pages.checkout.plans.moneyBackGuarantee")}
          </p>
        </div>
      </div>
    </div>
  )
}
