import ErrorPage from "@pages/ErrorPage"
import LoadingPage from "@pages/LoadingPage"
import { Suspense, useEffect } from "react"
import {
  Await,
  Navigate,
  useLoaderData,
  useSearchParams,
} from "react-router-dom"

export default function PaymentCompletionPage() {
  const data = useLoaderData() as { completed: Promise<boolean> }

  const [searchParams] = useSearchParams()

  const amount = searchParams.get("amount")!
  const currency = searchParams.get("currency")!
  const paymentIntentClientSecret = searchParams.get(
    "payment_intent_client_secret",
  )!

  const subscriptionId = searchParams.get("subscription_id")!

  useEffect(() => {
    const timeout = setTimeout(() => {
      // NOTE: Just wait one second to let the Facebook Pixel do its job
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Suspense fallback={<LoadingPage />}>
      <Await errorElement={<ErrorPage />} resolve={data.completed}>
        {(completed) =>
          completed ? (
            <Navigate to="/account/subscription" />
          ) : (
            <Navigate
              to={`/checkout/payment?amount=${amount}&currency=${currency}&client_secret=${paymentIntentClientSecret}&subscription_id=${subscriptionId}`}
            />
          )
        }
      </Await>
    </Suspense>
  )
}
