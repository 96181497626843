import discoverFemale from "@assets/discover_f.webp"
import discoverMale from "@assets/discover_m.webp"
import Button from "@components/Button"
import { useTranslation } from "react-i18next"
import { Form } from "react-router-dom"

export default function DiscoverPage() {
  const { t } = useTranslation()

  return (
    <main className="custom-container flex h-dvh flex-col justify-between space-y-14 overflow-y-auto pt-8">
      <div className="flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-center">
          <h1 className="heading my-3">
            {t("pages.onboarding.v2.discover.heading")}
          </h1>

          <img
            className="w-full max-w-xs px-8 pt-8"
            src={discoverFemale}
            alt="discover"
          />
          <img
            className="w-full max-w-xs px-8 pt-8"
            src={discoverMale}
            alt="discover"
          />
        </div>

        <Form className="py-8" method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>
    </main>
  )
}
