import appStoreAppOfTheYear2023White from "@assets/app_store_app_of_the_day_2023_white.webp"
import googleEditorsChoice2023White from "@assets/google_editors_choice_2023_white.webp"
import Button from "@components/Button"
import Layout from "@components/Layout"
import { useTranslation } from "react-i18next"
import { Form } from "react-router-dom"

export default function CompanyPage() {
  const { t } = useTranslation()

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading">{t("pages.onboarding.company.heading")}</h1>
        </div>

        <div className="flex grow flex-col justify-center space-y-8">
          <div className="flex items-center justify-center space-x-5">
            <img
              alt={t("pages.onboarding.company.appStoreAppOfTheYear2023")}
              className="w-36"
              src={appStoreAppOfTheYear2023White}
            />

            <img
              alt={t("pages.onboarding.company.googleEditorsChoice2023")}
              className="w-36"
              src={googleEditorsChoice2023White}
            />
          </div>

          <div className="space-y-4 text-center">
            <p className="font-openSans text-lg">
              {t("pages.onboarding.company.andHas")}
            </p>

            <p className="heading">
              {t("pages.onboarding.company.activeSubscribers")}
            </p>

            <p className="text-xl">⭐️⭐️⭐️⭐️⭐️</p>
          </div>
        </div>

        <div className="shrink-0">
          <Form method="post">
            <Button title={t("shared.continue")} type="submit" />
          </Form>
        </div>
      </div>
    </Layout>
  )
}
